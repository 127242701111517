import React from "react";

import Typography from '@mui/material/Typography';

function  Error404() {

    return (
        <Typography variant="h3">
            Error 404: Resource Not Found <br/>
        </Typography>
    )
}

export default Error404;
